import React, { useState } from 'react';
import NewLayout from '../layout/newLayout';
import NewArticle from '../components/newArticle';
import CtaBanner from "../components/ctaBanner";
import Title from "../components/title";
import SEO from "../components/seo";
import { slice as _slice } from 'lodash';
import cn from "classnames"
import styles from '../styles/scss/pages/blog.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import EventAnchor from '../components/eventAnchor';
import Hero from "../components/heroNewsroom";

const pageSize = 9;

const NewBlogTemplate = ({
    pageContext
}) => {
    const pageData = pageContext.page
    var relatedArticles = pageData.relatedArticles
    var relatedPressReleases = pageData.relatedPressReleases
    const { heroImage, heroMobile } = pageData
    const [currentPage, setCurrentPage] = useState(0);

    const [selectedTab, setSelectedTab] = useState('press-release');

    const heroLinks = [
        {
            id: 'press-release',
            slug: 'press-release',
            title: 'PRESS RELEASES',
        },
        {
            id: 'in-the-news',
            slug: 'in-the-news',
            title: 'IN THE NEWS',
        }        
    ]

    const selectType = (id) => {
        setSelectedTab(id)
    }

    // Effects
    React.useEffect(() => {
        const KeyCheck = function KeyCheck(e) {
            if (typeof document !== "undefined") {
                let KeyID = e.keyCode;
                if (KeyID === 8) window.history.go(-1); return false;
            }
        }

        if (typeof document !== "undefined") {
            document.addEventListener("keydown", KeyCheck)
        }
    }, []);

    const handleInfiniteScrollNext = React.useCallback(() => {
        setCurrentPage(s => s + 1);
    }, []);

    // Memos
    const {
        pagedBlogs,
        pages,
    } = React.useMemo(() => {
        const blogs = selectedTab === 'press-release' ? relatedPressReleases : relatedArticles;
        const out = {
            pagedBlogs: [],
            pages: 0,
            // totalBlogs: []
        };

        if (blogs && blogs.length) {
            // Paginate products
            const paginationStart = currentPage * pageSize;
            const paginationEnd = (currentPage * pageSize) + pageSize;
            const newpagedBlogs = _slice(blogs, paginationStart, paginationEnd);
            const previouspagedBlogs = _slice(blogs, 0, paginationStart);

            out.pagedBlogs = currentPage === 0 ? newpagedBlogs : [...previouspagedBlogs, ...newpagedBlogs];

            // Calculate Pages
            out.pages = Math.ceil(blogs.length / pageSize);
        }

        return out;
    }, [
        currentPage,
        selectedTab
    ]);

    // Disable SSR on this page
    if (typeof window === 'undefined') {
        return null;
    }

    return (
        <NewLayout>
            <SEO page={pageContext.page} />
            <Hero
                heroImage={heroImage}
                mobileimage={heroMobile}
                selectedTab={selectedTab}
                heroLinks={heroLinks}
                heroLinkClick={selectType}
            />
            <div className='d-flex flex-column flex-fill'>
                <InfiniteScroll
                    className={styles.infiniteContainer}
                    dataLength={pagedBlogs.length}
                    hasMore={currentPage <= (pages - 1)}
                    loader={() => (
                        <h4>
                            Loading...
                        </h4>
                    )}
                    endMessage={
                        <p className={styles.endMessage}>
                            There are no more products
                        </p>
                    }
                >
                    <div className={styles.topContentWrapper}>
                        <div
                            className={cn(styles.topContent, styles.newsroom)}
                        >
                            <Title
                                className={cn(styles.sitetitleNewLayout, styles.newsroomTitle)}
                                value={heroLinks.filter(item => item.id === selectedTab)[0]['title']}
                            />
                        </div>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.items}>
                            {pagedBlogs.length > 0 && pagedBlogs.map(blog => (
                                <div key={blog.id} className={styles.blogItem}>
                                    <NewArticle
                                        article={blog}
                                        imageClass={styles.itemImage}
                                        bodyClass={styles.itemBody}
                                    />
                                </div>
                            ))}
                            {currentPage < (pages - 1) &&
                                <div className={styles.seeMoreAnchor}>
                                    <EventAnchor
                                        text="More News >>"
                                        event={handleInfiniteScrollNext}
                                        condensed
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </InfiniteScroll>

            </div>

            <CtaBanner
                text="WANT TO GET MORE TIPS AND TRICKS?"
                buttonText="SUBSCRIBE TO THE NEWSLETTER"
                buttonUrl="/signup/"
            />
        </NewLayout>
    )
}

export default React.memo(NewBlogTemplate);