import React, { Component, Fragment } from "react"
import CustomModal from "./customModal"
import { Container } from "react-grid-system"
import cn from "classnames"
import styles from "../styles/scss/components/eventAnchor.module.scss"
export default class EventAnchor extends Component {
    state = { openVideo: false }
    render() {
        const { event, text, noMargin, video, condensed } = this.props
        return (
            <Container
                style={{ margin: 0, padding: 0 }}
                className={styles.container}
            >
                {video ? (
                    <Fragment>
                        <CustomModal
                            openVideo={this.state.openVideo}
                            setOpenVideo={() =>
                                this.setState({ openVideo: false })
                            }
                            youtubeVideoId={video.video.providerUid}
                        />

                        <button
                            className={cn(
                                { [styles.condensed]: condensed },
                                styles.clickableLabel,
                                { [styles.noMargin]: noMargin }
                            )}
                            onClick={() => this.setState({ openVideo: true })}
                        >
                            {text} &gt;&gt;
                        </button>
                    </Fragment>
                ) : (
                    <button
                        className={cn(
                            styles.clickableLabel,
                            { [styles.noMargin]: noMargin },
                            { [styles.condensed]: condensed }
                        )}
                        onClick={event}
                    >
                        {text}
                    </button>
                )}
            </Container>
        )
    }
}
