import React from 'react';
import Imgix from "react-imgix";

// Components
import Button from './button';
import HeroLinks from './newsHeroLinks';

import styles from '../styles/scss/components/newHeroHeader.module.scss';

const Hero = ({
  heroImage,
  mobileimage,
  selectedTab,
  heroLinks,
  heroLinkClick,
}) => {

  return (
    <div className="hero-header">
      <div className={styles.heroContainer}>
        {
          mobileimage &&
          <Imgix
            sizes='100vw'
            alt=''
            src={mobileimage.url || ''}
            className={styles.heroContainer_imageMobile}
            htmlAttributes={{ alt: '' }}
          />
        }
        <Imgix
          sizes='100vw'
          alt=''
          src={heroImage.url || ''}
          className={mobileimage ? styles.heroContainer_imageDesktop : styles.heroContainer_image}
          htmlAttributes={{ alt: '' }}
        />

        <div className={styles.newstitle}>
          WD-40<sub>®</sub>  Brand Newsroom
        </div>
      </div>
      <HeroLinks
        selectedTab={selectedTab}
        links={heroLinks}
        heroLinkClick={heroLinkClick}
      />
      <div className={styles.contactInfoContainer}>
        <div>
          <h3>
            Welcome to WD-40® Brand Newsroom
          </h3>
          <p>
            Learn how our brand delivers the trust and performance needed to instill confidence and empower people to get out there and get the Job Done Right®.
          </p>
          <div className={styles.buttonGroup}>
            <Button
              text="Media Contact"
              url='mailto:prinquiries@wd40.com'
              className={styles.button}
              target="_blank"
              newBlue
              pdf
              rel="noopener noreferrer"
            />
            <Button
              text="Social Links"
              url='https://linktr.ee/wd40brand'
              className={styles.button}
              target="_blank"
              newBlue
              pdf
              rel="noopener noreferrer"
            />
            <Button
              text="Email Sign-Up"
              url='https://www.wd40.com/signup/'
              className={styles.button}
              target="_blank"
              newBlue
              pdf
              rel="noopener noreferrer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Hero);
