import React, { Component } from 'react';
import ImageBlock from './imageBlock';
import styles from '../styles/scss/components/newArticle.module.scss'
import cn from 'classnames';
import Button from './button';
import moment from 'moment';
import { buildURL } from "react-imgix"

export default class Index extends Component {
    render() {
        const { article, titleImageClass, imageClass, bodyClass } = this.props;
        const url = '/article/' + article.slug + "/";
        return (
            <ImageBlock
                url={url}
                image={buildURL(article.heroImage.url, { fit: 'crop', crop: 'focalpoint', 'width': 357, 'height': 300, 'fm': 'jpg', 'q': 50 })}
                containerClass={styles.container}
                titleClass={cn(styles.titleImage, titleImageClass)}
                imageClass={cn(styles.image, imageClass, { [styles.hideImage]: !article.heroImage })}
                bodyClass={cn(styles.body, bodyClass)}>
                {article.title &&
                    <p className={styles.articleTitle}>
                        {article.title}
                    </p>
                }
                <p className={styles.time}>
                    <i className='fa fa-clock-o' /> {moment(article.dateOfArticle ? article.dateOfArticle : article.dateOfUse).format('MMMM D, YYYY')}
                </p>
                {article.teaser && <p className={styles.text}>{article.teaser}</p>}

                <Button
                    text="READ MORE"
                    url={url}
                    className={styles.button}
                    target="_self"
                    newBlue
                    // pdf
                    rel="noopener noreferrer"
                />
            </ImageBlock>
        );
    }
}
